import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const predefinedUsers = [
  { username: 'Daniel', password: 'Test123' },
  { username: 'Philipp', password: 'hoertenhuber1234' },
  { username: 'Harry', password: 'hoertenhuber1234' },
  { username: 'Iris', password: 'hoertenhuber1234' },
  { username: 'Goran', password: 'hoertenhuber1234' },
  { username: 'Jan', password: 'hoertenhuber1234' },
  { username: 'Gast', password: 'hoertenhuber1234' },
  // Add more predefined users as needed
];

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a user saved in localStorage
    const storedUser = JSON.parse(localStorage.getItem('currentUser'));
    if (storedUser) {
      setCurrentUser(storedUser);
    }
  }, []);

  const login = (username, password) => {
    const user = predefinedUsers.find(
      (user) => user.username === username && user.password === password
    );

    if (user) {
      const userInfo = { username: user.username };
      setCurrentUser(userInfo);
      localStorage.setItem('currentUser', JSON.stringify(userInfo)); // Save user info to localStorage
      setError(null);
      navigate('/'); // Redirect to the home page after successful login
    } else {
      setError('Invalid username or password');
    }
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('currentUser'); // Remove user info from localStorage
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};
