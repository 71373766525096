// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXwb4W6GDu2fXcLVpTxR2uoe23HG1VPBA",
  authDomain: "niroapp-3137b.firebaseapp.com",
  projectId: "niroapp-3137b",
  storageBucket: "niroapp-3137b.appspot.com",
  messagingSenderId: "913298010826",
  appId: "1:913298010826:web:7579e801e12fefa00efa33"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };