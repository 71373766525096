// src/theme.js

import { createTheme } from '@mui/material/styles';

// Modern Minimalistic Light Theme with Orange Primary Color
const modernLightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF9800', // Orange
    },
    secondary: {
      main: '#FF5722', // Deep Orange
    },
    background: {
      default: '#F5F5F5', // Light Gray
      paper: '#FFFFFF',    // White
    },
    text: {
      primary: '#212121', // Almost Black
      secondary: '#757575', // Gray
    },
    accent: {
      main: '#FFC107', // Amber
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: { fontWeight: 700, fontSize: '2.5rem' },
    h2: { fontWeight: 700, fontSize: '2rem' },
    h3: { fontWeight: 600, fontSize: '1.75rem' },
    body1: { fontSize: '1rem' },
    button: { textTransform: 'none' }, // Prevent uppercase transformation
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: '#FF9800',
          boxShadow: 'none',
          borderBottom: '1px solid #E0E0E0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
          },
        },
        containedPrimary: {
          backgroundColor: '#FF9800',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FB8C00',
          },
        },
        outlinedPrimary: {
          borderColor: '#FF9800',
          color: '#FF9800',
          '&:hover': {
            borderColor: '#FB8C00',
            backgroundColor: 'rgba(255, 152, 0, 0.04)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          boxShadow: '0 -1px 5px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 0,
          maxWidth: 250,
          '&.Mui-selected': {
            color: '#FF9800',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          padding: '20px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#FF9800',
          '&.Mui-checked': {
            color: '#FF9800',
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'rgba(255, 152, 0, 0.4)',
          },
        },
        track: {
          backgroundColor: 'rgba(255, 152, 0, 0.2)',
        },
      },
    },
  },
});

// Modern Minimalistic Dark Theme with Orange Primary Color
const modernDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFB74D', // Light Orange
    },
    secondary: {
      main: '#FF8A65', // Light Deep Orange
    },
    background: {
      default: '#121212', // Dark Charcoal
      paper: '#1E1E1E',    // Darker Gray
    },
    text: {
      primary: '#FFFFFF', // White
      secondary: '#B0B0B0', // Light Gray
    },
    accent: {
      main: '#FFC107', // Amber (Same as light theme for consistency)
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: { fontWeight: 700, fontSize: '2.5rem' },
    h2: { fontWeight: 700, fontSize: '2rem' },
    h3: { fontWeight: 600, fontSize: '1.75rem' },
    body1: { fontSize: '1rem' },
    button: { textTransform: 'none' }, // Prevent uppercase transformation
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1E1E1E',
          color: '#FFB74D',
          boxShadow: '0 2px 4px rgba(0,0,0,0.7)',
          borderBottom: '1px solid #333333',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
          },
        },
        containedPrimary: {
          backgroundColor: '#FFB74D',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FFA726',
          },
        },
        outlinedPrimary: {
          borderColor: '#FFB74D',
          color: '#FFB74D',
          '&:hover': {
            borderColor: '#FFA726',
            backgroundColor: 'rgba(255, 183, 77, 0.04)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 6px 16px rgba(0,0,0,0.4)',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1E1E1E',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#1E1E1E',
          boxShadow: '0 -1px 5px rgba(0,0,0,0.3)',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 0,
          maxWidth: 250,
          '&.Mui-selected': {
            color: '#FFB74D',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          padding: '20px',
          backgroundColor: '#1E1E1E',
          color: '#FFFFFF',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#FFB74D',
          '&.Mui-checked': {
            color: '#FFB74D',
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'rgba(255, 183, 77, 0.4)',
          },
        },
        track: {
          backgroundColor: 'rgba(255, 183, 77, 0.2)',
        },
      },
    },
  },
});

export { modernLightTheme, modernDarkTheme };
