import React, {createContext, useState, useEffect} from 'react';
import {db} from '../firebase'; // Import your Firebase configuration
import {collection, addDoc, getDocs, deleteDoc, doc, updateDoc} from 'firebase/firestore';
import {getStorage, ref, deleteObject} from 'firebase/storage';

export const AppContext = createContext();

export const AppProvider = ({children}) => {
    const [jobs, setJobs] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [consumables, setConsumables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const jobsCollection = collection(db, "jobs");
    const workersCollection = collection(db, "workers");
    const consumablesCollection = collection(db, "consumables");

    const storage = getStorage(); // Initialize Firebase Storage

    // Fetch jobs from Firestore
    const fetchJobs = async () => {
        try {
            setLoading(true);
            const data = await getDocs(jobsCollection);
            const fetchedJobs = data.docs.map((doc) => ({
                firestoreId: doc.id,
                ...doc.data(),
            }));
            setJobs(fetchedJobs);
        } catch (error) {
            console.error("Error fetching jobs: ", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch workers from Firestore
    const fetchWorkers = async () => {
        try {
            setLoading(true);
            const data = await getDocs(workersCollection);
            const fetchedWorkers = data.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setWorkers(fetchedWorkers);
        } catch (error) {
            console.error("Error fetching workers: ", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch consumables from Firestore
    const fetchConsumables = async () => {
        try {
            setLoading(true);
            const data = await getDocs(consumablesCollection);
            const fetchedConsumables = data.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setConsumables(fetchedConsumables);
        } catch (error) {
            console.error("Error fetching consumables: ", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Use the fetch methods on component mount
    useEffect(() => {
        fetchJobs();
        fetchWorkers();
        fetchConsumables();
    }, []);

    // Add a new job
    const addJob = async (job) => {
        try {
            const newJobDoc = await addDoc(jobsCollection, job);
            setJobs([...jobs, {...job, firestoreId: newJobDoc.id}]);
        } catch (error) {
            console.error("Error adding job: ", error);
            setError(error);
        }
    };

    // Update an existing job
    const updateJob = async (updatedJob) => {
        try {
            const jobDoc = doc(db, "jobs", updatedJob.firestoreId);
            await updateDoc(jobDoc, updatedJob);
            setJobs(jobs.map((job) => (job.firestoreId === updatedJob.firestoreId ? updatedJob : job)));
        } catch (error) {
            console.error("Error updating job: ", error);
            setError(error);
        }
    };

    // Remove a job
    const removeJob = async (job) => {
        try {
            if (job.firestoreId) {
                const jobDoc = doc(db, "jobs", job.firestoreId);
                await deleteDoc(jobDoc);
                setJobs(jobs.filter((j) => j.firestoreId !== job.firestoreId));
            }
        } catch (error) {
            console.error("Error removing job: ", error);
            setError(error);
        }
    };

    // Complete a job
// Complete a job
    const completeJob = async (job) => {
        try {
            if (job.firestoreId) {
                const jobDoc = doc(db, 'jobs', job.firestoreId);
                await updateDoc(jobDoc, {completed: true, completedAt: job.completedAt});
                setJobs(jobs.map((j) => (j.firestoreId === job.firestoreId ? job : j)));
            }
        } catch (error) {
            console.error("Error completing job: ", error);
            setError(error);
        }
    };


    // Add a new worker
    const addWorker = async (worker) => {
        try {
            const newWorkerDoc = await addDoc(workersCollection, {name: worker});
            setWorkers([...workers, {id: newWorkerDoc.id, name: worker}]);
        } catch (error) {
            console.error("Error adding worker: ", error);
            setError(error);
        }
    };

    // Remove a worker
    const removeWorker = async (worker) => {
        try {
            const workerDoc = doc(db, "workers", worker.id);
            await deleteDoc(workerDoc);
            setWorkers(workers.filter((w) => w.id !== worker.id));
        } catch (error) {
            console.error("Error removing worker: ", error);
            setError(error);
        }
    };

    // Add a new consumable
    const addConsumable = async (consumable) => {
        try {
            const newConsumableDoc = await addDoc(consumablesCollection, consumable);
            setConsumables([...consumables, {id: newConsumableDoc.id, ...consumable}]);
        } catch (error) {
            console.error("Error adding consumable: ", error);
            setError(error);
        }
    };

    // Remove a consumable and delete its associated image from Firebase Storage
    const removeConsumable = async (consumableId) => {
        try {
            // Find the consumable by ID to get the image URL
            const consumableToDelete = consumables.find((c) => c.id === consumableId);

            if (!consumableToDelete) {
                console.error("Consumable not found");
                return;
            }

            // Delete the document from Firestore
            const consumableDoc = doc(db, 'consumables', consumableId);
            await deleteDoc(consumableDoc);

            // If the consumable has an associated image URL, delete the image from Firebase Storage
            if (consumableToDelete.imageUrl) {
                const imageRef = ref(storage, consumableToDelete.imageUrl);
                await deleteObject(imageRef);
                console.log(`Deleted image from Firebase Storage: ${consumableToDelete.imageUrl}`);
            }

            // Remove the consumable from local state
            setConsumables(consumables.filter((c) => c.id !== consumableId));
        } catch (error) {
            console.error("Error removing consumable: ", error);
            setError(error);
        }
    };

    return (
        <AppContext.Provider value={{
            jobs,
            addJob,
            updateJob,
            removeJob,
            completeJob,
            workers,
            addWorker,
            removeWorker,
            consumables,
            addConsumable,
            removeConsumable,
            fetchJobs,       // Expose fetchJobs for refreshing jobs
            fetchWorkers,    // Expose fetchWorkers for refreshing workers
            fetchConsumables, // Expose fetchConsumables for refreshing consumables
            loading,
            error,
        }}>
            {children}
        </AppContext.Provider>
    );
};