// src/App.js

import React, {useState, useEffect, Suspense, lazy} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Container,
    CssBaseline,
    ThemeProvider,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Typography,
    Divider,
    Switch,
    useMediaQuery,
    BottomNavigation,
    BottomNavigationAction,
    GlobalStyles,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {AuthProvider, useAuth} from './context/AuthContext';
import {AppProvider} from './context/AppContext';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import deLocale from 'date-fns/locale/de';

// Import modern themes
import {modernLightTheme, modernDarkTheme} from './theme';

// Import assets
import logo from './assets/logo.webp';
import favicon from './assets/Favicon.png';

// Lazy load your pages and components
const Home = lazy(() => import('./pages/Home'));
const JobsPage = lazy(() => import('./pages/JobsPage'));
const CalendarPage = lazy(() => import('./pages/CalendarPage'));
const ConsumablesPage = lazy(() => import('./pages/ConsumablesPage'));
const JobOverviewPage = lazy(() => import('./pages/JobOverviewPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const Login = lazy(() => import('./components/Login'));
const ToolsPage = lazy(() => import('./pages/ToolsPage'));
const CutOptimizer = lazy(() => import('./pages/CutOptimizer'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));

// iOS Detection for SwipeableDrawer performance optimization
const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

// Main Content Component
function AppContent() {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const {currentUser, logout} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [bottomNavValue, setBottomNavValue] = useState(0);

    // Initialize theme from localStorage
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        setIsDarkMode(savedTheme === 'dark');
    }, []);

    // Toggle between light and dark mode
    const toggleTheme = () => {
        const newTheme = !isDarkMode ? 'dark' : 'light';
        setIsDarkMode(!isDarkMode);
        localStorage.setItem('theme', newTheme);
    };

    // Toggle Drawer
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    // Handle Logout
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    // Navigation Links
    const navLinks = [
        {title: 'Startseite', path: '/', icon: <HomeIcon/>},
        {title: 'Aufträge', path: '/jobs', icon: <WorkIcon/>},
        {title: 'Kalender', path: '/calendar', icon: <CalendarTodayIcon/>},
        {title: 'Bestellliste', path: '/consumables', icon: <LocalOfferIcon/>},
        {title: 'Tools', path: '/tools', icon: <BuildIcon/>},
        {title: 'Einstellungen', path: '/settings', icon: <SettingsIcon/>},
    ];

    // Update Bottom Navigation based on current route
    useEffect(() => {
        const currentIndex = navLinks.findIndex(link => link.path === location.pathname);
        setBottomNavValue(currentIndex !== -1 ? currentIndex : 0);
    }, [location.pathname, navLinks]);

    // Side Effects: Setting favicon, document title, and meta description
    useEffect(() => {
        // Set favicon
        const faviconLink = document.querySelector("link[rel='icon']");
        if (faviconLink) {
            faviconLink.href = favicon;
        } else {
            const newFavicon = document.createElement('link');
            newFavicon.rel = 'icon';
            newFavicon.href = favicon;
            document.head.appendChild(newFavicon);
        }

        // Set document title
        document.title = 'NiroApp';

        // Set meta description
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.content = 'NiroApp';
        } else {
            const newMeta = document.createElement('meta');
            newMeta.name = 'description';
            newMeta.content = 'NiroApp';
            document.head.appendChild(newMeta);
        }
    }, []);

    return (
        <ThemeProvider theme={isDarkMode ? modernDarkTheme : modernLightTheme}>
            <CssBaseline/>
            {/* Global Styles */}
            <GlobalStyles
                styles={{
                    '*': {
                        boxSizing: 'border-box',
                    },
                    body: {
                        margin: 0,
                        padding: 0,
                        backgroundColor: isDarkMode
                            ? modernDarkTheme.palette.background.default
                            : modernLightTheme.palette.background.default,
                        fontFamily: modernLightTheme.typography.fontFamily,
                    },
                    a: {
                        color: isDarkMode
                            ? modernDarkTheme.palette.primary.main
                            : modernLightTheme.palette.primary.main,
                        textDecoration: 'none',
                    },
                }}
            />
            {/* AppBar for Desktop */}
            {!isMobile && (
                <AppBar position="fixed">
                    <Toolbar>
                        {/* Menu Icon for Desktop */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{mr: 2}}
                        >
                            <MenuIcon/>
                        </IconButton>

                        {/* Logo */}
                        <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                            <Link to="/">
                                <img src={logo} alt="NiroApp Logo" style={{height: '40px'}}/>
                            </Link>
                        </Box>

                        {/* Navigation Links for Desktop */}
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {navLinks.map(link => (
                                <Button
                                    key={link.title}
                                    color="primary"
                                    component={Link}
                                    to={link.path}
                                    startIcon={link.icon}
                                    sx={{marginLeft: 2}}
                                >
                                    {link.title}
                                </Button>
                            ))}

                            {/* Theme Toggle */}
                            <IconButton
                                sx={{ml: 2}}
                                onClick={toggleTheme}
                                color="inherit"
                                aria-label="Toggle theme"
                            >
                                {isDarkMode ? <Brightness7Icon/> : <Brightness4Icon/>}
                            </IconButton>

                            {/* Login/Logout Button */}
                            {currentUser ? (
                                <Button
                                    color="secondary"
                                    onClick={handleLogout}
                                    startIcon={<LogoutIcon/>}
                                    sx={{marginLeft: 2}}
                                >
                                    Logout
                                </Button>
                            ) : (
                                <Button
                                    color="secondary"
                                    component={Link}
                                    to="/login"
                                    startIcon={<LoginIcon/>}
                                    sx={{marginLeft: 2}}
                                >
                                    Login
                                </Button>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
            )}

            {/* Swipeable Drawer */}
            <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableBackdropTransition={!isIOS}
                disableDiscovery={isIOS}
            >
                <Box
                    sx={{width: 250}}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', p: 2}}>
                        <img src={logo} alt="NiroApp Logo" style={{height: '40px'}}/>
                        <Typography variant="h6" sx={{ml: 1}}>
                            NiroApp
                        </Typography>
                    </Box>
                    <Divider/>
                    <List>
                        {navLinks.map(link => (
                            <ListItem button key={link.title} component={Link} to={link.path}>
                                <ListItemIcon>{link.icon}</ListItemIcon>
                                <ListItemText primary={link.title}/>
                            </ListItem>
                        ))}

                        {/* Theme Toggle */}
                        <ListItem>
                            <ListItemIcon>
                                {isDarkMode ? <Brightness7Icon/> : <Brightness4Icon/>}
                            </ListItemIcon>
                            <ListItemText primary="Dark Mode"/>
                            <Switch checked={isDarkMode} onChange={toggleTheme}/>
                        </ListItem>

                        {/* Login/Logout */}
                        {currentUser ? (
                            <ListItem button onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Logout"/>
                            </ListItem>
                        ) : (
                            <ListItem button component={Link} to="/login">
                                <ListItemIcon>
                                    <LoginIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Login"/>
                            </ListItem>
                        )}
                    </List>
                </Box>
            </SwipeableDrawer>

            {/* Main Content */}
            <Box
                sx={{
                    mt: isMobile ? 0 : 8,
                    // Adjust the bottom margin to accommodate the BottomNavigation's height and safe area inset
                    mb: isMobile ? `calc(56px + env(safe-area-inset-bottom))` : 0,
                    p: 2,
                }}
            >
                <Container maxWidth="lg">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route
                                path="/"
                                element={
                                    <PrivateRoute>
                                        <Home/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/jobs"
                                element={
                                    <PrivateRoute>
                                        <JobsPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/calendar"
                                element={
                                    <PrivateRoute>
                                        <CalendarPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/consumables"
                                element={
                                    <PrivateRoute>
                                        <ConsumablesPage/>
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/job-overview/:jobId"
                                element={
                                    <PrivateRoute>
                                        <JobOverviewPage/>
                                    </PrivateRoute>
                                }
                            />
        <Route
          path="/tools"
          element={
            <PrivateRoute>
              <ToolsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/tools/cut-optimizer"
          element={
            <PrivateRoute>
              <CutOptimizer />
            </PrivateRoute>
          }
        />
                            <Route
                                path="/settings"
                                element={
                                    <PrivateRoute>
                                        <SettingsPage toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
                                    </PrivateRoute>
                                }
                            />
                        </Routes>
                    </Suspense>
                </Container>
            </Box>

            {/* Bottom Navigation for Mobile */}
            {isMobile && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        backgroundColor: isDarkMode
                            ? modernDarkTheme.palette.background.paper
                            : modernLightTheme.palette.background.paper,
                        paddingTop: '8px', // Optional: Adjust as needed
                        paddingBottom: 'env(safe-area-inset-bottom)',
                        boxShadow: '0 -1px 5px rgba(0,0,0,0.1)', // Optional: Adds a subtle shadow
                    }}
                >
                    <BottomNavigation
                        showLabels
                        value={bottomNavValue}
                        onChange={(event, newValue) => {
                            setBottomNavValue(newValue);
                        }}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            // Ensure the BottomNavigation takes the full width and height
                            width: '100%',
                            minHeight: '56px', // Default height for BottomNavigation
                        }}
                    >
                        <BottomNavigationAction
                            label="Startseite"
                            icon={<HomeIcon/>}
                            component={Link}
                            to="/"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // Optionally adjust icon size
                                '& .MuiSvgIcon-root': {
                                    fontSize: '24px', // Adjust icon size as needed
                                },
                            }}
                        />
                        <BottomNavigationAction
                            label="Aufträge"
                            icon={<WorkIcon/>}
                            component={Link}
                            to="/jobs"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiSvgIcon-root': {
                                    fontSize: '24px',
                                },
                            }}
                        />
                        <BottomNavigationAction
                            label="Kalender"
                            icon={<CalendarTodayIcon/>}
                            component={Link}
                            to="/calendar"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiSvgIcon-root': {
                                    fontSize: '24px',
                                },
                            }}
                        />
                        <BottomNavigationAction
                            label="Bestellliste"
                            icon={<LocalOfferIcon/>}
                            component={Link}
                            to="/consumables"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiSvgIcon-root': {
                                    fontSize: '24px',
                                },
                            }}
                        />
                        <BottomNavigationAction
                            label="Einstellungen"
                            icon={<SettingsIcon/>}
                            component={Link}
                            to="/settings"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '& .MuiSvgIcon-root': {
                                    fontSize: '24px',
                                },
                            }}
                        />
                    </BottomNavigation>
                </Box>
            )}
        </ThemeProvider>
    );
}

function App() {
    return (
        <Router>
            <AuthProvider>
                <AppProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                        <AppContent/>
                    </LocalizationProvider>
                </AppProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
